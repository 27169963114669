import React from "react";
import {ReactComponent as Logo} from './../assets/go-digital-footer.svg';
import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <div className="container grid grid-cols-3 sm:grid-cols-1 py-7 grid-rows-5 sm:grid-rows-3 gap-y-4 bg-gray200 pl-leftBody pr-rightBody border-t-8 border-solid border-blue-b700">
            <div className="col-span-2 sm:col-span-full row-span-4 grid sm:w-full">
                <h3 className="text-h3 sm:text-h3m sm:pb-3 font-bold self-center row-span-1 sm:row-span-full border-b-2  border-gray-g400">Das Projekt UX Design Agenturen</h3>
                <p className="text-sp border-b-2 sm:pt-3 sm:border-b-0 border-gray-g400 self-center h-full sm:h-full row-span-3">Im Rahmen der Initiative der Verbände, enger für die Digitalisierung zusammenzuarbeiten, wurden die besten User Experience Design Agenturen in Deutschland bewertet und hier aufgeführt. Dies soll den Mitgliedsunternehmen in Deutschland und der Schweiz helfen, enger im Netzwerk zusammenzuarbeiten und wettbewerbsfähigere Lösungen zu entwickeln.</p>

            </div>
            <div className="col-span-1 row-span-4 grid  sm:row-span-1 sm:w-full">
                <h3 className="text-h3 sm:pb-3 sm:text-h3m font-bold row-span-1 border-b-2 border-gray-g400 pb-1 ml-4 sm:ml-0 self-center">Mitglieder</h3>
                <div className="border-b-2 sm:border-b-0 border-gray-g400 row-span-3">
                    <div className="grid grid-rows-f4 h-full text-sp ml-4 sm:ml-0 items-baseline sm:h-40 sm:items-center">
                        <a href="https://www.swissict.ch/" className="text-gray700 hover:underline hover:text-gray600 active:underline active:text-gray700">Swiss ICT</a>
                        <a href="https://www.teletrust.de/startseite/" className="text-gray700 hover:underline hover:text-gray600 active:underline active:text-gray700">TeleTrusT</a>
                        <a href="https://databund.de/" className="text-gray700 hover:underline hover:text-gray600 active:underline active:text-gray700">Databund</a>
                        <a href="https://www.bitmi.de/" className="text-gray700 hover:underline hover:text-gray600 active:underline active:text-gray700">BITMi</a>
                    </div>
                </div>

            </div>
            <div className="col-span-3 sm:col-span-1 sm:row-span-1 grid items-center sm:w-full">
                <Link to={'/'}><Logo /></Link>
                <div className="col-start-3 sm:col-start-1 items-center flex flex-row justify-end sm:justify-start h-full max-h-6 sm:mt-4 text-sp divide-x divide-gray-g400">
                <Link className="pl-4 pr-4 text-gray700 hover:underline hover:text-gray600 active:underline active:text-gray700"
                 to={'/impressum'}>Impressum</Link>
                <Link className="pl-4 pr-4 text-gray700 hover:underline hover:text-gray600 active:underline active:text-gray700" to={'/datenschutz'}>Datenschutz</Link>
                <p className="pl-4">© {(new Date().getFullYear())}</p>
                </div>

            </div>
        </div>
    )
}

export default Footer;