import React, { useState, useEffect, Suspense, lazy } from "react";
import agenciesData from './../agencies.json';

const AgencySection = () => {

    const [showAll, setShowAll] = useState(false);
    const [agencies, setAgencies] = useState([]);

    const handleShowMore = () => {
        setShowAll(true);
    }

    useEffect(() => {
        setAgencies(agenciesData.agencies);
      }, []);
    
      const AgencyCard = lazy(() => import('../components/AgencyCard'));

    const allAgencies = agencies.map((agency, index) => {
        return <Suspense fallback={<div>loading</div>}>
                    <AgencyCard key={index} agency={agency} />
                </Suspense>
        
    });

    const showButton = allAgencies.length - 1 < 4;

    return(
        <div className="container bg-gray200 pt-16">
            <h2 className="text-h2 sm:text-h2m font-bold text-left ml-leftBody mr-rightBody">Rangliste der UX Design Agenturen</h2>
            <p className="text-lp mt-6 mb-12 sm:mb-2 ml-leftBody mr-rightBody">Die UX Design Agenturen sind nach der Anzahl der Punkte sortiert, die sie vom Expertengremium erhalten haben.</p>
            <div className="pb-12">
                {showAll ? allAgencies : allAgencies.splice(0,4)}
                {
                    !showAll && !showButton && (
                        <button onClick={handleShowMore} className="text-blue-b500 text-sp font-semibold hover:text-blue-b600 hover:underline hover:font-semibold active:text-blue-b700 active:underline active:font-semibold m-auto my-10 sm:my-0 flex justify-center">Mehr Design Agenturen anzeigen</button>
                    )
                }
            </div>
        </div>
    )
}

export default AgencySection;