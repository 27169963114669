import {React, useState, useEffect} from "react";

const CookieModal = () => {

    const [isOpen, setIsOpen] = useState(!localStorage.getItem('cookiesAccepted'));

    useEffect(() => {
        if(!localStorage.getItem('cookiesAccepted')){
            localStorage.setItem('cookiesAccepted',false)
            setIsOpen(true);
        }
      }, []);
    
    const closeModal = () =>{  
    localStorage.setItem('cookiesAccepted', true );
    setIsOpen(false)
    }

    return (
        <div className={isOpen ? "p-8 w-[30%] sm:w-[80%] bg-white fixed right-6 bottom-6 z-50 flex-col ml-4 sm:ml-0 flex shadow-[0_4px_20px_0_#0000001F]":"hidden"}>
            <h3 className="text-h3  font-bold font-title mb-2">Cookies und andere Technologien</h3>
            <p className="text-sp text-black">Wit setzen Cookies und andere Technologien ein, um die Inhalte optimal für Sie zu gestalten. Weitere Infos finden Sie in unseren Datenschutzbestimmungen. Technisch notwendige Cookies werden auch bei der Auswahl von ablehnen gesetzt.</p>
            <div className="flex flex-row mt-4">
                <button className="bg-gray300 hover:bg-gray400 active:bg-gray500 mr-2 h-12 w-44 sm:w-full sm:mt-6 text-black" onClick={()=> closeModal()}>Ablehnen</button>
                <button className="bg-blue-b500 hover:bg-blue-b600 active:bg-blue-b700 ml-2 h-12 w-44 sm:w-full sm:mt-6 text-white" onClick={()=> closeModal()}>Einwilligen</button>
            </div>
        </div>
        
    )
}

export default CookieModal;
