import React from "react";

const MarketSection = () => {
    return (
        <div>
            <div className="container bg-white grid grid-cols-5 sm:grid-cols-1 pl-leftBody sm:pl-0 sm:pr-0 pr-rightBody my-6 sm:mt-3 pt-12 sm:pt-6 sm:mb-10">
                <div className="col-span-3 mr-2 sm:pl-leftBody sm:pr-rightBody">
                    <h2 className="text-h2 sm:text-h2m font-title font-bold mb-10 sm:mb-8">Markt der UX Design Agenturen in Deutschland</h2>
                    <p className="text-sp mb-10 sm:mb-8">Der Markt für Designagenturen in Deutschland ist vielfältig und dynamisch, geprägt von einer starken Nachfrage nach innovativen und nutzerzentrierten Lösungen. Diese Agenturen bieten eine breite Palette an Dienstleistungen an, darunter Marktanalyse, Usability-Tests, Prototyping, User Research und Interaktionsdesign. Der Fokus liegt dabei auf der Schaffung von benutzerfreundlichen und ästhetisch ansprechenden digitalen Produkten, die die Bedürfnisse und Erwartungen der Nutzer erfüllen.</p>
                    <h3 className="text-h3 font-bold mb-4">Berlin, Hamburg, München und Frankfurt dominieren UX-Agenturen</h3>
                    <p className="text-sp mb-10 sm:mb-6">Die UX-Agenturen in Deutschland sind regional vielfältig verteilt, mit einem starken Fokus auf die Hauptstädte. In Berlin, Hamburg, München und Frankfurt befinden sich die meisten Agenturen. Diese Städte sind Zentren für digitale Innovation und bieten ein dynamisches Umfeld für UX-Design, das nationale und internationale Kunden anzieht. Auch in Städten wie Köln, Düsseldorf und Stuttgart gibt es bedeutende UX-Agenturen, die zur Vielfalt und Innovationskraft des deutschen Marktes beitragen.</p>
                    <h3 className="text-h3 sm:text-h3m font-bold mb-4">Die Entwicklung der UX Agenturen in Deutschland</h3>
                    <p className="text-sp mb-10 sm:mb-6">In Deutschland gibt es verschiedene Arten von UX-Agenturen, darunter spezialisierte Boutiquen, Full-Service-Agenturen und Beratungsfirmen. Früher dominierten traditionelle Full-Service-Agenturen den Markt. In den letzten Jahren haben sich jedoch immer mehr spezialisierte Boutiquen etabliert, die sich auf spezifische Aspekte wie Mobile UX, Usability-Tests und User Research konzentrieren. Diese Entwicklung reflektiert die zunehmende Bedeutung von maßgeschneiderten und innovativen Lösungen im UX-Bereich.</p>
                    <h3 className="text-h3 sm:text-h3m font-bold mb-4">Aufbau der typischen UI Agentur</h3>
                    <p className="text-sp mb-10 sm:mb-6">UI-Agenturen in Deutschland sind typischerweise in interdisziplinäre Teams organisiert, bestehend aus UI-Designern, UX-Designern, Entwicklern und Projektmanagern. Die Struktur fördert enge Zusammenarbeit und agile Arbeitsmethoden, um kreative und effiziente Lösungen zu entwickeln. Oft gibt es spezialisierte Abteilungen für User Research, Prototyping und visuelles Design. Diese Teams arbeiten eng mit Kunden zusammen, um maßgeschneiderte und benutzerfreundliche Interfaces zu erstellen.</p>
                    <h3 className="text-h3 sm:text-h3m font-bold mb-4">Modelle der Zusammenarbeit mit Kunden und UX-Agenturen </h3>
                    <p className="text-sp mb-10 sm:mb-6">Deutsche UX-Agenturen bieten verschiedene Engagement-Modelle an, um flexibel auf Kundenbedürfnisse einzugehen. Diese Modelle umfassen projektbasierte Zusammenarbeit, Retainer-Vereinbarungen und dedizierte Teams. Bei projektbasierter Zusammenarbeit wird ein spezifisches Projekt mit festem Umfang umgesetzt. Retainer-Vereinbarungen ermöglichen kontinuierliche Unterstützung über einen längeren Zeitraum. Dedizierte Teams arbeiten eng und exklusiv mit dem Kunden zusammen, um nahtlose Integration und schnelle Reaktionszeiten zu gewährleisten.</p>
                    <h3 className="text-h3 sm:text-h3m font-bold mb-4">Zukunftsvisionen</h3>
                    <p className="text-sp mb-10 sm:mb-6">In den nächsten drei Jahren werden deutsche UX-Agenturen zunehmend auf Künstliche Intelligenz, Augmented Reality und personalisierte Nutzererfahrungen setzen. Der Fokus wird auf der Integration neuer Technologien und der Verbesserung der digitalen Barrierefreiheit liegen. Die Nachfrage nach spezialisierten Agenturen und maßgeschneiderten Lösungen wird weiter steigen.</p>

                </div>
                <div className="col-span-2 ml-6 sm:ml-0">
                    <hr className="bg-blue-b700 h-2 w-full"></hr>
                    <h3 className="text-h3 sm:text-h3m font-bold mt-2 sm:pl-leftBody sm:pr-rightBody">Weiterführende Links</h3>
                    <div className="flex flex-col sm:pl-leftBody sm:pr-rightBody">
                        <a href="https://grow.google/certificates/ux-design/" className="mb-4 mt-2 font-semibold text-blue-b500 hover:text-blue-b600 hover:underline active:text-blue-b700 active:underline text-sp">Google UX Design Zertifizierung</a>
                        <a href="https://www.linkedin.com/groups/150012/" className="mb-4 font-semibold text-blue-b500 hover:text-blue-b600 hover:underline active:text-blue-b700 active:underline text-sp">LinkedIn Gruppe der Produktmanager</a>
                        <a href="https://www.bundesnetzagentur.de/DE/Fachthemen/Digitalisierung/Mittelstand/Best-Practice/start.html" className="mb-4 font-semibold text-blue-b500 hover:text-blue-b600 hover:underline active:text-blue-b700 active:underline text-sp">Fachthema Digitalisierung (Bundesnetzagentur)</a>
                        <a href="https://digital-strategy.ec.europa.eu/de/policies/web-accessibility-directive-standards-and-harmonisation#:~:text=Die%20Richtlinie%20(EU)%202016%2F,mobilen%20Apps%20zug%C3%A4nglich%20zu%20machen" className="mb-4 font-semibold text-blue-b500 hover:text-blue-b600 hover:underline active:text-blue-b700 active:underline text-sp">Richtlinie digitale Barrierefreiheit</a>
                        <a href="https://www.interaction-design.org/" className="mb-4 font-semibold text-blue-b500 hover:text-blue-b600 hover:underline active:text-blue-b700 active:underline text-sp">Interaction Design Foundation</a>
                        <a href="https://www.bundesfachstelle-barrierefreiheit.de/DE/Home/home_node.html" className="mb-4 font-semibold text-blue-b500 hover:text-blue-b600 hover:underline active:text-blue-b700 active:underline text-sp">Bundesfachstelle digitales Fachwissen</a>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default MarketSection;