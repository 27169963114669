import React from "react";
import Logo1 from './../assets/logo-1.svg';
import Logo2 from './../assets/logo-2.svg';
import Logo3 from './../assets/logo-3.svg';
import Logo4 from './../assets/logo-4.svg';

const currentYear = new Date().getFullYear();
const HeroSection = () => {
    return(
        <div>
            <div className="container bg-blue-b500 h-heroSection flex items-center">
                <div className="pl-leftBody pr-leftBody flex flex-col">
                    <h1 className="text-h1 sm:text-h1m font-title text-white font-bold mb-4">Einstufung der User Experience (UX)<br></br> Design Agenturen in Deutschland <br></br> 
                    in {currentYear}</h1>
                    <p className="text-lp text-white">Eine Auswertung des Arbeitskreises "2030 Kompetenz Produkt Management" der <br></br>  berufsständischen Verbände im Bereich der Informationstechnologie.</p>
                </div>
            </div>
            <div className="sm:h-52 h-24 bg-blue-b600 content-center">
                <div className="pl-leftBody pr-leftBody sm:py-4 flex sm:grid sm:grid-cols2 sm:grid-rows-3 sm:w-full sm:gap-2 flex-row sm:flex-col justify-between sm:place-content-around sm:items-center items-center align-middle h-full sm:justify-center">
                    <p className="text-sp text-white sm:col-span-2 sm:mx-auto">Eine initiative von</p>
                    <img loading="lazy" src={Logo1} alt="logo-1" className="sm:row-start-2 sm:col-start-1 sm:justify-self-center w-40 h-12"></img>
                    <img loading="lazy" src={Logo2} alt="logo-2" className="sm:row-start-3 sm:col-start-2 sm:justify-self-center w-40 h-12"></img>
                    <img loading="lazy" src={Logo3} alt="logo-3" className="sm:row-start-3 sm:col-start-1 sm:justify-self-center w-40 h-12"></img>
                    <img loading="lazy" src={Logo4} alt="logo-4" className="sm:row-start-2 sm:col-start-2 sm:justify-self-center w-40 h-12"></img>

                </div>
            </div>

        </div>
    )
}

export default HeroSection;