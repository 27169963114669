import '../App.css';
import IntroSection from '../sections/IntroSection';
import AgencySection from '../sections/AgencySection';
import MarketSection from '../sections/MarketSection';
import React, { useState, useEffect } from 'react';
import agenciesData from '.././agencies.json';

function HomePage() {
  let jsonSchemaStructured = {
    "@type": "https://schema.org/CollectionPage",
    "@id": "#page",
    "url": "",
    "name": "UX Agenturen",
    "description": "",
    "mainContentOfPage": {
      "@type": "WebPageElement",
      "name": "Rangliste der UX Design Agenturen",
      "description": "Die UX Design Agenturen sind nach der Anzahl der Punkte sortiert, die sie vom Expertengremium erhalten haben."
    },
    "reviewedBy": {
      "@type": "Organization",
      "name": "UX Agenturen",
      "url": "https://uxagenturen.de",
      "@id": "#org"
    }
  }

  const [agencies, setAgencies] = useState([]);

  useEffect(() => {
    setAgencies(agenciesData.agencies);
  }, []);

  const allAgenciesSchema = agencies.map((agency) =>
  ({
    "@type": "Organization",
    "url": agency.url,
    "name": agency.name,
    "descripion": agency.about,
    "aggregateRating":
    {
      "@type": "AggregateRating",
      "ratingValue": agency.punkte,
      "bestRating": "100",
      "worstRating": "0",
      "ratingCount": "24"
    },
    "logo":
    {
      "@type": "ImageObject",
      "url": require(`.././assets/agency-cards/${agency.name.toLowerCase().split(' ').join('-')}/${agency.logo}`),
      "contentUrl": agency.url,
      "caption": agency.name,
      "height": "72px",
      "width": "72px"
    },
    "numberOfEmployees": agency.employees,
    "audience": agency.audience,
    "award":
      agency.award.split(", ").map(awardValue => {
        return awardValue;
      }),
    "founder": agency.owner,
    "foundingDate": agency.founded,
    "hasOfferCatalog":
    {
      "@type": "OfferCatalog",
      "name": "Leistungen der Design Agentur",
      "itemListElement":
        agency.services.split(", ").map(service => {
          const serviceJson = {
            '@type': 'ItemList',
            'name': service
          };
          return serviceJson;
        })
    }

  })
  );
  jsonSchemaStructured.mainEntity = allAgenciesSchema;
  jsonSchemaStructured.maintainer = {
    "@id": "#org"
  };

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonSchemaStructured),
        }}
      />
      <div>
        <IntroSection />
        <AgencySection />
        <MarketSection />
      </div>
    </>
  );
}

export default HomePage;
