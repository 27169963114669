import React from 'react';
import Logo from './../assets/go-digital-logo.svg';
import { Link } from 'react-router-dom';

const Header = () => {
    return(
        <div className="container min-h-10 items-center bg-white flex justify-between pl-leftBody pr-rightBody py-4 border-b-8 border-solid border-blue-b700">
            <Link to={'/'}><img loading="lazy" src={Logo} alt='logo' className='sm:w-32 w-40 h-16'/></Link>
        </div>
    )
}

export default Header;