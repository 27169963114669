import '../App.css';
import React from "react";
import { Outlet } from 'react-router';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookieModal from '../components/CookieModal';

function Layout() {
  return (
    <>
      <Header />
      <CookieModal />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
