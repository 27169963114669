import React from "react";

const Roadmap = () => {
    return (
        <div className="container my-4 h-72 content-center sm:overflow-scroll sm:w-full">
            <div className="pl-leftBody pr-leftBody flex flex-row justify-center align-center sm:w-[150%]">
                <div before="Entwicklung Kriterien durch Sachverständigenausschuss" className="sm:pr-6 sm:ml-8 text-st font-semibold border-8 rounded-full h-10 w-10 border-blue-b700 relative ml-20
                before:absolute before:left-1/2 before:top-[33px] before:h-8  before:border-l-2 before:border-dotted before:border-gray500 before:translate-x-[-50%]
                after:content-[attr(before)] after:absolute after:left-1/2 after:top-[70px] after:translate-x-[-50%]"></div>

                <div className=" w-[13%] border-t-2 translate-y-[50%] border-dotted border-blue-b500"></div>

                <div before="Öffentliches Verfahren" className="sm:pr-6 text-st font-semibold border-8 rounded-full h-10 w-10 border-blue-b700 relative
                before:absolute before:left-1/2 before:bottom-[33px] before:h-8  before:border-l-2 before:border-dotted before:border-gray500 before:translate-x-[-50%]
                after:content-[attr(before)] after:absolute after:left-1/2 after:bottom-[70px] after:translate-x-[-50%]">
                </div>

                <div className=" w-[13%] border-t-2 translate-y-[50%] border-solid border-blue-b700"></div>
                
                <div before="Erstregistrierung der Teilnehmer" className="sm:pr-6 text-st font-semibold border-8 rounded-full h-10 w-10 border-blue-b700 relative
                before:absolute before:left-1/2 before:top-[33px] before:h-8  before:border-l-2 before:border-dotted before:border-gray500 before:translate-x-[-50%]
                after:content-[attr(before)] after:absolute after:left-1/2 after:top-[70px] after:translate-x-[-50%]">
                </div>

                <div className=" w-[13%] border-t-2 translate-y-[50%] border-solid border-blue-b700"></div>

                <div before="Begutachtung der Dokumentation durch Expertenkommission" className="sm:pr-6 text-st font-semibold border-8 rounded-full h-10 w-10 border-blue-b700 relative
                before:absolute before:left-1/2 before:bottom-[33px] before:h-8  before:border-l-2 before:border-dotted before:border-gray500 before:translate-x-[-50%]
                after:content-[attr(before)] after:absolute after:left-1/2 after:bottom-[70px] after:translate-x-[-50%]">
                </div>

                <div className=" w-[13%] border-t-2 translate-y-[50%] border-solid border-blue-b700"></div>

                <div before="Veröffentlichung der Ergebnisse" className="sm:pr-6 text-st font-semibold border-8 rounded-full h-10 w-10 border-blue-b500 relative
                before:absolute before:left-1/2 before:top-[33px] before:h-8  before:border-l-2 before:border-dotted before:border-gray500 before:translate-x-[-50%]
                after:content-[attr(before)] after:absolute after:left-1/2 after:top-[70px] after:translate-x-[-50%]">
                </div>

                <div className=" w-[13%] border-t-2 translate-y-[50%] border-dotted border-blue-b500"></div>

                <div before="Fortlaufende Überprüfung" className="sm:pr-6 sm:mr-8 text-st font-semibold border-8 rounded-full h-10 w-10 border-blue-b500 relative mr-20
                before:absolute before:left-1/2 before:bottom-[33px] before:h-8  before:border-l-2 before:border-dotted before:border-gray500 before:translate-x-[-50%]
                after:content-[attr(before)] after:absolute after:left-1/2 after:bottom-[70px] after:translate-x-[-50%]">
                </div>

            </div>
        </div>

    )
}

export default Roadmap