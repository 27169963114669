import React from "react";
import HeroSection from './HeroSection';
import Roadmap from './../components/Roadmap';

const IntroSection = () => {
    return(
        <div className="container">
            <HeroSection />
            <div className="max-w-225 mt-8 sm:max-w-none m-auto">
                <h1 className="sm:text-h2m text-h1 font-bold font-title text-black text-center mb-7 pl-leftBody pr-rightBody">Das Bewertungsverfahren der UX Design Agenturen</h1>
            </div>
            <Roadmap />
            {/* <h2 className='text-h2 sm:text-h2m font-bold text-black m-auto text-center mb-4 pl-leftBody pr-rightBody'>Video title</h2> */}
            {/* <div className='m-auto aspect-video max-h-150 mb-16 pl-leftBody pr-rightBody' >
                <iframe 
                    className=' max-h-150 h-full w-full rounded-lg'
                    src="https://www.youtube.com/embed/4WiH9pf2ULQ?si=2TzjHgKzRDOgi528" 
                    width="100%" 
                    title="YouTube video player" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen> 
                </iframe>
            </div> */}
        </div>
    )
}

export default IntroSection;