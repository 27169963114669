import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './routes/Root';
import React, { Suspense, lazy  } from "react"; 
import ScrollToTop from './helpers/ScrollToTop';
import HomePage from './pages/Home';
import { Helmet } from 'react-helmet';

function App() {

  
  const currentYear = new Date().getFullYear();
  const metaContent = `User Experience (UX) Design Agenturen ${currentYear} - Go Digital`;

  const ImpressumPage = lazy(() => import('./pages/Impressum'));
  const DatenschutzPage = lazy(() => import('./pages/Datenschutz'));

  return (
    <>
      <Helmet>
        <title>{metaContent}</title>
        <meta name="keywords" content="UX Design Agenturen, User Experience Agenturen, Marktanalyse, UI Agenturen, UX Designer, Deutschland, Berlin, Hamburg, Frankfurt, München, User Research Agenturen, User Experience Beratung, User Interface Design, Usability Berater" />
        <meta name="description" content="Ergebnisse der Einstufung der besten UX Design Agenturen in Deutschland, initiative gefördert durch das Bundesministerium für Wirtschaft und Klimaschutz" />
        <meta name="title" content={metaContent} />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={
             <HomePage />
              } />
            <Route path='/impressum' element={
              <Suspense fallback={<div>loading</div>}>
                <ImpressumPage />
              </Suspense>

            } />
            <Route path='/datenschutz' element={
              <Suspense fallback={<div>loading</div>}>
              <DatenschutzPage />
            </Suspense>
             
              } />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
